import React from "react";
import { Container } from "../components/Container";
import Webcam from "react-webcam";
import { useEffect, useState, useRef } from "react";
import Countdown from "react-countdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import HIPPALogo from "./../img/hipaa-compliant.png";

export function Camera(props) {
  const params = useParams();
  let navigate = useNavigate();
  const id = params.tripId;
  console.log("camera ID", id);
  const webcamRef = useRef(null);
  const videoConstraints = {
    width: 480,
    height: 720,
    facingMode: "user",
  };

  const onUserMedia = (stream) => {
    console.log("User has granted camera permission");
    setTimeout(() => {
      onComplete();
    }, 3000);
  };
  const onUserMediaError = () => {
    toast.error("User has not granted camera permission ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // setTimeout(() => {
    //   // navigate("/thanks");
    //   props.setThankYouStep(true);
    // }, 5000);
  };
  function onComplete() {
    let payload = {
      status: "confirmation",
      company: props.tripDetails.company._id,
      assign: id,
      imageSrc:
        webcamRef.current.getScreenshot({ width: 480, height: 720 }) || null,
    };
    console.log("imageSrc", payload.imageSrc);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(`https://nemtpanel.com/api/fwas`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        // navigate("/thanks");
        props.setThankYouStep(true);
      });
  }

  return (
    <>
      <section>
        <Container>
          <div className="min-h-screen">
            <div className="h-auto pt-4 px-4 bg-blue-50 pb-10">
              <img
                src={HIPPALogo}
                width={160}
                height={64}
                className="object-scale-down"
                alt=""
              />
            </div>
            <div className="p-4 relative -mt-8">
              <div className="bg-white shadow h-full p-4 rounded-lg space-y-8">
                <Webcam
                  className="h-full w-full"
                  ref={webcamRef}
                  onUserMedia={onUserMedia}
                  onUserMediaError={onUserMediaError}
                  mirrored={true}
                  audio={false}
                  screenshotFormat="image/png"
                  screenshotQuality={0.5}
                  forceScreenshotSourceSize="true"
                  width={480}
                  height={720}
                  videoConstraints={videoConstraints}
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <ToastContainer />
    </>
  );
}
