import clsx from "clsx";
import { Link } from "react-router-dom";

const baseStyles = {
  solid:
    "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
  outline:
    "group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none duration-300 ease-in-out",
};

const variantStyles = {
  solid: {
    slate:
      "bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900",
    primary:
      "bg-is-primary text-white hover:text-is-light hover:bg-is-primary/90 active:bg-is-primary/90 active:text-is-light focus-visible:outline-is-primary",
    white:
      "bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white",
  },
  outline: {
    slate:
      "ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300",
    white:
      "ring-white text-white hover:ring-is-secondary hover:bg-is-secondary focus-visible:outline-white",
  },
};

export function Button({
  variant = "solid",
  color = "slate",
  className,
  to,
  ...props
}) {
  className = clsx(
    baseStyles[variant],
    variantStyles[variant][color],
    className
  );

  return to ? (
    <Link to={to} className={className} {...props} />
  ) : (
    <button className={className} {...props} />
  );
}
