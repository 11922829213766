import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home/index";
import ThanksScreen from "./thanks";
import TripID from "./trip/[id]";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/trip/:tripId" element={<TripID />} />
          <Route path="/thanks" element={<ThanksScreen />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
