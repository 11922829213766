import { Container } from "../components/Container";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { Button } from "../components/Button";
import { Triangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import HIPPALogo from "./../img/hipaa-compliant.png";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function MemberConfirmation(props) {
  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();

  let navigate = useNavigate();

  //   console.log("props", props);

  function ConfirmationValidation(action) {
    if (!action) {
      console.log("action", "move to other page");
      navigate("/thanks");
    }
    props.setValidate(action);
  }

  return (
    <>
      {props.tripDetails ? (
        <section>
          <Container>
            <div>
              <div className="h-auto pt-4 px-4 bg-blue-50 pb-10">
                <img
                  src={HIPPALogo}
                  width={160}
                  height={64}
                  className="object-scale-down"
                  alt=""
                />
              </div>

              <div className="p-4 relative -mt-8">
                <div className="bg-white shadow h-full px-4 py-8 rounded-lg space-y-8">
                  <div className="mx-auto h-16 w-16 rounded-full overflow-hidden grid place-content-center bg-blue-50 p-2 shadow">
                    <LockClosedIcon className="h-6 w-6 text-blue-500" />
                  </div>
                  <div className="space-y-4 text-center">
                    <h1 className="text-xl font-bold text-gray-900">
                      To complete this process, we need to confirm your name and
                      date of birth.
                    </h1>
                    <div className="flex flex-col text-gray-500 font-bold text-2xl gap-2">
                      <div>You are</div>
                      <div className="text-is-secondary bg-blue-50 px-4 py-2 rounded">
                        {props.tripDetails.priorityClient
                          ? props.tripDetails.priorityClient.displayName
                          : "Not Valid Name"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-base font-medium leading-6 text-gray-500 text-left">
                        Date of birth
                      </label>
                      <div className="mt-1 grid grid-cols-3 gap-2">
                        <div>
                          <DatePicker
                            selected={day}
                            onChange={(date) => setDay(date)}
                            dateFormat="dd"
                            placeholderText="Day"
                          />
                        </div>
                        <div>
                          <DatePicker
                            selected={month}
                            onChange={(date) => setMonth(date)}
                            showMonthYearPicker
                            dateFormat="MM"
                            placeholderText="Month"
                          />
                        </div>
                        <div>
                          <DatePicker
                            selected={year}
                            onChange={(date) => setYear(date)}
                            showYearPicker
                            dateFormat="yyyy"
                            placeholderText="Year"
                            yearItemNumber={20}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-center gap-4">
                    <Button onClick={() => ConfirmationValidation(true)}>
                      YES It&apos;s Me
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => ConfirmationValidation(false)}
                    >
                      No It&apos;s Not
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      ) : (
        <div className="fixed inset-0 grid place-content-center bg-white">
          <Triangle
            height="100"
            width="100"
            color="#fca311"
            ariaLabel="loading"
          />
        </div>
      )}
    </>
  );
}
