import React from "react";
import { Container } from "../components/Container";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { Button } from "../components/Button";
import HIPPALogo from "./../img/hipaa-compliant.png";

export default function VerificationRequired(props) {
  function ConfirmationValidationRequired(action) {
    props.setVerificationStepOne(action);
    console.log("setVerificationStepOne", action);
  }

  return (
    <>
      <section>
        <Container>
          <div>
            <div className="h-auto pt-4 px-4 bg-blue-50 pb-10">
              <img
                src={HIPPALogo}
                width={160}
                height={64}
                className="object-scale-down"
                alt=""
              />
            </div>
            <div className="p-4 relative -mt-8">
              <div className="bg-white shadow h-full px-4 py-8 rounded-lg space-y-8">
                <div className="mx-auto h-16 w-16 rounded-full overflow-hidden grid place-content-center bg-blue-50 p-2 shadow">
                  <LockClosedIcon className="h-6 w-6 text-blue-500" />
                </div>
                <div className="space-y-4 text-center">
                  <h1 className="text-xl font-bold text-gray-900">
                    Verification Required
                  </h1>
                  <p className="text-lg text-gray-500">
                    We are required to verify your identity as per your state
                    transportation benefit payer guidelines. This will ensure
                    that you receive the correct transportation services.
                  </p>
                </div>
                <Button
                  className="w-full"
                  onClick={() => ConfirmationValidationRequired(true)}
                >
                  Please Proceed
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
