import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../components/Button";
import { useNavigate } from "react-router-dom";

export function Hero() {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    navigate(`/trip/${data.trackingID}`);
  };
  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              className="h-10 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Trip Confirmation
            </h2>
          </div>

          <div className="mt-10">
            <div>
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <label
                    htmlFor="trackingID"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Tracking ID
                  </label>
                  <div className="mt-2">
                    <input
                      id="trackingID"
                      name="trackingID"
                      type="text"
                      {...register("trackingID", { required: true })}
                      aria-invalid="true"
                      aria-describedby="trackingID-error"
                    />
                  </div>
                  {errors.trackingID && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="trackingID-error"
                    >
                      Please fill the field
                    </p>
                  )}
                </div>

                <div>
                  <Button className="w-full">Find Confirmation Trip</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
