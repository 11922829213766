import React from "react";
import { Container } from "../components/Container";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Button } from "../components/Button";
import HIPPALogo from "./../img/hipaa-compliant.png";

export function ThankYou(props) {
  return (
    <section>
      <Container>
        <div>
          <div className="h-auto pt-4 px-4 bg-blue-50 pb-10">
            <img
              src={HIPPALogo}
              width={160}
              height={64}
              className="object-scale-down"
              alt=""
            />
          </div>
          <div className="p-4 relative -mt-8">
            <div className="bg-white shadow h-full px-4 py-8 rounded-lg space-y-8">
              <div className="mx-auto h-16 w-16 rounded-full overflow-hidden grid place-content-center bg-green-50 p-2 shadow">
                <CheckIcon className="h-6 w-6 text-green-500" />
              </div>
              <div className="space-y-4">
                <h1 className="text-xl font-bold text-gray-900">
                  Thank you{" "}
                  <span className="text-is-secondary">
                    {" "}
                    {props.tripDetails.priorityClient
                      ? props.tripDetails.priorityClient.displayName
                      : "Not Valid Name"}
                  </span>
                </h1>
                <p className="text-lg text-gray-500">
                  for confirming your ride and identity. If you have any
                  questions or concerns, please don't hesitate to contact us at{" "}
                  <a
                    href={`telto:${props.tripDetails.company.extension}`}
                    className="text-is-secondary text-lg font-medium"
                  >
                    {props.tripDetails.company
                      ? props.tripDetails.company.extension
                      : "Not Valid phone"}
                  </a>
                  . We are committed to providing you with a safe and reliable
                  transportation service and experience.
                </p>
                <div className="text-lg text-gray-500">
                  <div>Best regards,</div>
                  <div className="text-is-secondary font-bold inline-block">
                    {" "}
                    {props.tripDetails.company
                      ? props.tripDetails.company.displayName
                      : "Not Valid Name"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
